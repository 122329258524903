<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <div class="d-flex flex-column flex-sm-row mt-n3 pt-3 pb-sm-3 toolbar">
      <v-btn
        v-show="selectedItems.length === 0"
        color="success"
        class="mr-lg-auto mr-sm-4"
        :loading="uploading"
        :large="!$vuetify.breakpoint.xsOnly"
        :fab="$vuetify.breakpoint.xsOnly"
        :fixed="$vuetify.breakpoint.xsOnly"
        :bottom="$vuetify.breakpoint.xsOnly"
        :right="$vuetify.breakpoint.xsOnly"
        @click="uploadClicked"
      >
        <v-icon :left="!$vuetify.breakpoint.xsOnly">mdi-upload</v-icon>
        <span class="d-none d-sm-flex">Importar XML</span>
      </v-btn>

      <div v-show="selectedItems.length > 0" class="mr-lg-auto mr-sm-4" />

      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="application/xml"
        @change="fileSelected"
      />

      <month-picker-field class="mr-sm-4 mb-4" v-model="monthFilter" />

      <div class="mb-4 flex-grow-1 flex-lg-grow-0">
        <search-field
          clearable
          label="Fornecedor ou Nota Fiscal"
          v-model="searchFilter"
        />
      </div>
    </div>

    <v-row>
      <v-col cols="12">
        <nota-fiscal-table
          recipient-header="Fornecedor"
          :loading="loading"
          :search="searchFilter"
          :items="purchases"
          @input="selectedItems = $event"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { HttpError } from "@/components/Error";
import MonthPickerField from "@/components/MonthPickerField";
import SearchField from "@/components/SearchField";
import NotaFiscalTable from "@/components/NotaFiscal/Table";

export default {
  name: "PurchaseList",
  metaInfo() {
    return {
      title: "Nota Fiscal Recebida",
    };
  },
  components: {
    MonthPickerField,
    SearchField,
    NotaFiscalTable,
  },
  data: () => ({
    uploading: false,
    monthFilter: new Date().toISOString().substr(0, 7),
    searchFilter: "",
    selectedItems: [],
  }),
  computed: {
    purchases() {
      return this.items.map((item) => ({
        serie: item.serie,
        number: item.number,
        issueDate: item.issue_date,
        operationDescription: item.operation.description,
        recipient: item.supplier,
        total: item.totals.total,
        status:
          item.status == "pending" ? `purchase_${item.status}` : item.status,
        sefazId: item.sefaz_id,
        authorized: !!item.authorized,
        canceled: !!item.canceled,
        viewHref: {
          name: "purchase-view",
          params: { id: item.id },
        },
        xmlUrl: "",
        pdfUrl: "",
      }));
    },
    ...mapState("auth", ["company"]),
    ...mapState("purchase", ["loading"]),
    ...mapGetters("purchase", ["items"]),
  },
  watch: {
    company() {
      this.$nextTick().then(() => {
        // when a different company is selected, reset monthFilter
        this.monthFilter = new Date().toISOString().substr(0, 7);
        this.loadPurchases();
      });
    },
    monthFilter() {
      this.loadPurchases();
    },
  },
  mounted() {
    this.loadPurchases();
  },
  methods: {
    async loadPurchases() {
      this.$store.dispatch("purchase/fetch", {
        month: this.monthFilter,
      });
    },
    uploadClicked() {
      this.$refs.uploader.click();
    },
    async fileSelected(e) {
      this.uploading = true;

      const form = new FormData();
      form.set("file", e.target.files[0]);

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      try {
        const { data } = await this.$http.post(
          "/v1/purchases/import-xml",
          form,
          { headers }
        );

        this.$router.push({
          name: "purchase-view",
          params: { id: data.id },
        });
      } catch (err) {
        if (err instanceof HttpError) {
          switch (err.code) {
            case "purchase_already_exists":
              this.notifyError("Nota Fiscal já consta em seu sistema");
              break;
            case "invalid_status":
              this.notifyError("Nota Fiscal não está autorizada ou cancelada");
              break;
            case "unknown_company":
              this.notifyError("Nota Fiscal não foi emitida para esta empresa");
              break;
            default:
              this.$sentry.captureException(err);
              this.notifyError("Não foi possível importar o arquivo XML 😢");
          }
        } else {
          console.error(err);
          this.$sentry.captureException(err);
        }
      } finally {
        this.$refs.uploader.value = null;
        this.uploading = false;
      }
    },
  },
};
</script>
